'use client';

import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useTranslations } from 'next-intl';
import { useTaxonomy } from '@/contexts/taxonomy.context';
import { useCategory } from '@/hooks/useCategory';
import { ListIcon } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import CategoryListItem from '@/components/custom/category-list-item';
import { ICategory } from '@/types/types';
import RootCategoryMenuList from './root-category-menu-list';

export default function DesktopCategoriesButton() {
  const t = useTranslations();
  const {
    mainCategories,
    selectedRootCategory,
    setSelectedRootCategory,
    setSelectedSubCategory,
    isLoading
  } = useTaxonomy();
  const { getSubCategories } = useCategory();

  const [subCategories, setSubCategories] = useState<ICategory[] | null>(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState<ICategory | null>(null);
  const [nestedSubCategories, setNestedSubCategories] = useState<Record<number, ICategory[]>>({});

  const loadSubCategories = useCallback(async (categoryId: number) => {
    const data = await getSubCategories(categoryId);
    if (data) {
      setSubCategories(data);
      setNestedSubCategories((prev) => ({ ...prev, [categoryId]: data }));
    }
  }, [getSubCategories]);

  useEffect(() => {
    if (selectedRootCategory) {
      loadSubCategories(selectedRootCategory.category_id);
    }
  }, [selectedRootCategory, loadSubCategories]);

  const handleRootHover = (category: ICategory) => {
    setSelectedRootCategory(category);
    setHoveredCategory(category);
    setOpenPopover(true);
  };

  const handleSubClick = async (sub: ICategory) => {
    setSelectedSubCategory(sub);
    const children = await getSubCategories(sub.category_id);
    if (children && children.length > 0) {
      setNestedSubCategories((prev) => ({ ...prev, [sub.category_id]: children }));
    }
  };

  return (
    <Popover open={openPopover} onOpenChange={setOpenPopover}>
      <PopoverTrigger asChild>
        <Button variant="outline" size="icon">
          <ListIcon className="w-5 h-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex gap-4 max-w-5xl w-full p-4">
      <div className="h-[400px] overflow-hidden">
        <RootCategoryMenuList onHover={handleRootHover} />
      </div>
        <div className="w-1/3">
          <h4 className="font-semibold mb-2">
            {hoveredCategory?.name ?? 'Subcategories'}
          </h4>
          <ScrollArea className="max-h-[300px] pr-2">
            <div className="grid grid-cols-2 gap-2">
              {subCategories?.map((sub) => (
                <Button
                  key={sub.category_id}
                  variant="ghost"
                  className="justify-start text-left"
                  onClick={() => handleSubClick(sub)}
                >
                  {sub.name}
                </Button>
              ))}
            </div>
          </ScrollArea>
        </div>

        <div className="w-1/3">
          {subCategories?.map((sub) => (
            nestedSubCategories[sub.category_id]?.length ? (
              <div key={sub.category_id}>
                <h5 className="text-sm font-medium mb-1">{sub.name}</h5>
                <div className="grid grid-cols-2 gap-2 mb-4">
                  {nestedSubCategories[sub.category_id].map((nested) => (
                    <Button
                      key={nested.category_id}
                      variant="ghost"
                      className="justify-start text-left"
                      onClick={() => setSelectedSubCategory(nested)}
                    >
                      {nested.name}
                    </Button>
                  ))}
                </div>
              </div>
            ) : null
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}
