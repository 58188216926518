import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/frontend/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/next/dist/client/legacy/image.js");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Geist\",\"arguments\":[{\"variable\":\"--font-geist-sans\",\"subsets\":[\"latin\"]}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Geist_Mono\",\"arguments\":[{\"variable\":\"--font-geist-mono\",\"subsets\":[\"latin\"]}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AuthButton"] */ "/var/www/frontend/src/components/buttons/auth-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/buttons/cart-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/buttons/desktop-categories-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/buttons/language-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/var/www/frontend/src/components/buttons/mode-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserButton"] */ "/var/www/frontend/src/components/buttons/user-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WatchlistButton"] */ "/var/www/frontend/src/components/buttons/watchlist-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/custom/search-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/nav/mobile-categories-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecentlyViewedProvider"] */ "/var/www/frontend/src/providers/recently-viewed.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/providers/redux.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchProvider"] */ "/var/www/frontend/src/providers/search.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaxonomyProvider"] */ "/var/www/frontend/src/providers/taxonomy.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/providers/ui.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WatchlistProvider"] */ "/var/www/frontend/src/providers/watchlist.provider.tsx");
