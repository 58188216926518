'use client';

import { createContext, useContext } from 'react';

export interface SearchContextType {
  currentQuery: string;
  setCurrentQuery: (query: string) => void;
  results: any[];
  setResults: (results: any[]) => void;
  searchHistory: string[];
  addToHistory: (query: string) => void;
  removeFromHistory: (query: string) => void;
  clearHistory: () => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  isError: string | null;
  setIsError: (error: string | null) => void;
}

export const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const useSearch = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
