import { CNFlag } from "@/components/icons/flags/cn";
import { DEFlag } from "@/components/icons/flags/de";
import { ESFlag } from "@/components/icons/flags/es";
import { ILFlag } from "@/components/icons/flags/il";
import { RUFlag } from "@/components/icons/flags/ru";
import { SAFlag } from "@/components/icons/flags/sa";
import { USFlag } from "@/components/icons/flags/us";
import { ReactNode } from "react";

export type ILanguageMenuItem = {
  id: string;
  name: string;
  value: string;
  icon: ReactNode;
}

export const languageMenu: ILanguageMenuItem[] = [
  // {
  //   id: 'ar',
  //   name: 'عربى - AR',
  //   value: 'ar',
  //   icon: <SAFlag width="20px" height = "15px" />
  // },
  // {
  //   id: 'zh',
  //   name: '中国人 - ZH',
  //   value: 'zh',
  //   icon: <CNFlag width="20px" height = "15px" />,
  // },
  {
    id: 'en',
    name: 'English - EN',
    value: 'en',
    icon: <USFlag width="20px" height = "15px" />,
  },
  {
    id: 'ru',
    name: 'Русский - RU',
    value: 'ru',
    icon: <RUFlag width="20px" height = "15px" />,
  },
  // {
  //   id: 'de',
  //   name: 'Deutsch - DE',
  //   value: 'de',
  //   icon: <DEFlag width="20px" height = "15px" />,
  // },
  // {
  //   id: 'he',
  //   name: 'rעברית - HE',
  //   value: 'he',
  //   icon: <ILFlag width="20px" height = "15px" />,
  // },
  {
    id: 'es',
    name: 'Español - ES',
    value: 'es',
    icon: <ESFlag width="20px" height = "15px" />,
  },
];