'use client';

import { Button } from '@/components/ui/button';
import { IUser } from '@/types/types';
import { UserIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';

type UserButtonProps = {
  user: IUser;
}

export const UserButton = ({ user }: UserButtonProps) => {
  const router = useRouter();

  return (
    <Button
      variant="default"
      size="icon"
      className="relative shrink-0"
      onClick={() => router.push('/profile')}
    >
      <UserIcon className="size-[1.2rem]" />
    </Button>
  );
};
