'use effect';

import { useEffect, useState } from 'react';

export const useMinWidthQuery = (minWidth: number) => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(`(min-width: ${minWidth - 1}px)`);
    const updateMatches = () => setMatches(mediaQueryList.matches);

    updateMatches();
    mediaQueryList.addEventListener('change', updateMatches);

    return () => {
      mediaQueryList.removeEventListener('change', updateMatches);
    };
  }, [minWidth]);

  return matches;
};
