'use client';

import { Button } from '@/components/ui/button';
import { HeartIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';

export const WatchlistButton = () => {
  const router = useRouter();

  return (
    <Button
      variant="outline"
      size="icon"
      // className="relative shrink-0"
      onClick={() => router.push('/watchlist')}
    >
      <HeartIcon className="size-[1.2rem]" />
      {/* <Badge
        variant="destructive"
        className={`absolute -right-2 -top-1.5 rounded-full px-1 text-[9px] leading-none ${watchlistCount === 0 ? 'bg-muted text-muted-foreground' : 'bg-destructive text-white'}`}
      >
        {watchlistCount}
      </Badge> */}
    </Button>
  );
};
