'use client';

import { appConfig } from "@/config";
import { getCookie, setCookie } from "@/lib/api/cookie/cookie.client";
import { logError, logInfo } from "@/lib/api/logger/logger.client";
import { ICookieType } from "@/lib/api/types/api.types";

export const useProductCookies = (cookieType: 'watchlist' | 'recent') => {
  const storeCookies = (cookieType: ICookieType, cookieValue: string): void => {
    try {
      const lifetimeDays = appConfig.cookies.lifetimeDays[cookieType] ?? 7;
      const cookieName = `w_${cookieType}`;
      setCookie(cookieName, cookieValue, lifetimeDays);
    } catch (error: unknown) {
      let logMessage = `Failed to store cookie of type ${cookieType}. `;
      if (error instanceof Error) logMessage += error.message;
      logError(logMessage, { cookieValue });
    }
  }

  const clearCookies = (cookieType: ICookieType): void => {
    try {
      document.cookie = `w_${cookieType}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Lax`;
    } catch (error: unknown) {
      let logMessage = `Failed to clear cookie of type ${cookieType}. `;
      if (error instanceof Error) logMessage += error.message;
      logError(logMessage);
    }
  };

  const storeProductIds = (cookieType: 'recent' | 'watchlist' | 'cart', productIds: number[]) => {
    const cookieValue = JSON.stringify(productIds);
    storeCookies(cookieType, cookieValue);
  }

  const getProductIds = (cookieType: 'recent' | 'watchlist' | 'cart'): number[] | null => {
    const cookieValue = getCookie(`w_${cookieType}`);

    if (!cookieValue) {
      logInfo(`Cookie of type ${cookieType} not found.`, { cookieValue });
      return null;
    }

    return JSON.parse(cookieValue);
  }

  const getWatchlistIds = () => getProductIds('watchlist');
  const getRecentlyViewedIds = () => getProductIds('recent');
  const getCartIds = () => getProductIds('cart');
  const storeCartIds = (productIds: number[]) => storeProductIds('cart', productIds);
  const storeRecentlyViewedIds = (productIds: number[]) => storeProductIds('recent', productIds);
  const storeWatchlistIds = (productsIds: number[]) => storeProductIds('watchlist', productsIds);
  const storeJwtAccessToken = (token: string) => storeCookies('access_token', token);
  const clearWatchlistIds = () => clearCookies('watchlist');
  const clearRecentlyViewedIds = () => clearCookies('recent');
  const clearCartIds = () => clearCookies('cart');

  return {
    storeProductIds,
    clearWatchlistIds,
    clearRecentlyViewedIds,
    clearCartIds,
    storeJwtAccessToken,
    storeWatchlistIds,
    storeRecentlyViewedIds,
    getCartIds,
    getRecentlyViewedIds,
    getWatchlistIds,
    storeCartIds,
  }
}