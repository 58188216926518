'use client';

import { endpoints } from "@/config/endpoints";
import { buildHeaders, sendRequest } from "@/lib/api/http/http.client";
import { buildApiUrl, buildRequest, handleApiResponse } from "@/lib/api/http/http.index";
import { logError } from "@/lib/api/logger/logger.client";
import { IApiException, IProductPageDataResponse } from "@/lib/api/types/api.types";
import { transformProductFiltersQuery } from "@/lib/link-manager";
import { IProduct, IProductDetails, IProductFilters, IProductVariant } from "@/types/types";
import { useCallback, useState } from "react";

const useApi = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getProductsByIds = useCallback(async (productIds: number[]): Promise<IProduct[] | null> => {
    setIsLoading(true);
    try {
      const controller = new AbortController();
      const apiUrl = buildApiUrl(endpoints.content.products.GET_BY_IDS);
      const headers = buildHeaders('POST', { withAccessToken: true });
      const request = buildRequest('POST', headers, controller.signal, { product_ids: productIds });
      const apiResponse = await sendRequest<IProduct[] | IApiException>(apiUrl, request, controller, 3000);
      if (isError) setIsError(false);
      return handleApiResponse<IProduct[]>(apiResponse);
    } catch (error: unknown) {
      logError(error, { details: 'getting products by IDs failed in useApi hook.', productIds });
      setIsError(true);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [isError]);

  const getVariants = useCallback(async (variantIds: number[]): Promise<IProductVariant[] | null> => {
    setIsLoading(true);
    try {
      const controller = new AbortController()
      const apiUrl = buildApiUrl(endpoints.content.products.GET_VARIANTS);
      const headers = buildHeaders('POST', { withAccessToken: true });
      const request = buildRequest('POST', headers, controller.signal, { variant_ids: variantIds });
      const apiResponse = await sendRequest<IProductVariant[] | IApiException>(apiUrl, request, controller, 5000);
      if (isError) setIsError(false);
      return handleApiResponse<IProductVariant[]>(apiResponse);
    } catch (error: unknown) {
      logError(error, { details: 'failed on getting variants', variantIds });
      setIsError(true);
      return null;
    } finally {
      setIsLoading(true);
    }
  }, [isError]);

  const getProductVariants = useCallback(async (productId: number): Promise<IProductVariant[]> => {
    const controller = new AbortController();
    const apiUrl = buildApiUrl(endpoints.content.products.GET_PRODUCT_VARIANTS, { product_id: productId });
    const headers = buildHeaders('GET', { withAccessToken: true });
    const request = buildRequest('GET', headers, controller.signal);
    const apiResponse = await sendRequest<IProductVariant[] | IApiException>(apiUrl, request, controller, 5000);
    return handleApiResponse<IProductVariant[]>(apiResponse);
  }, []);

  const getDetails = useCallback(async (productId: number): Promise<IProductDetails> => {
    const controller = new AbortController();
    const apiUrl = buildApiUrl(endpoints.content.products.GET_DETAILS, { product_id: productId });
    const headers = buildHeaders('GET', { withAccessToken: true });
    const request = buildRequest('GET', headers, controller.signal);
    const apiResponse = await sendRequest<IProductDetails | IApiException>(apiUrl, request, controller, 5000);
    return handleApiResponse<IProductDetails>(apiResponse);
  }, []);

  const getProductDetails = useCallback(async (productId: number): Promise<IProductPageDataResponse> => {
    const controller = new AbortController();
    const apiUrl = buildApiUrl(endpoints.content.products.GET_DETAILS, { product_id: productId });
    const headers = buildHeaders('GET', { withAccessToken: true });
    const request = buildRequest('GET', headers, controller.signal);
    const apiResponse = await sendRequest<IProductPageDataResponse | IApiException>(apiUrl, request, controller, 5000);
    return handleApiResponse<IProductPageDataResponse>(apiResponse);
  }, []);

  const getFilteredProducts = useCallback(async (filters: IProductFilters): Promise<IProduct[] | null | undefined> => {
    const apiUrl = buildApiUrl(endpoints.content.products.GET_FILTERED);
    try {
      const controller = new AbortController();
      const transformedFilters = transformProductFiltersQuery(filters);
      const headers = buildHeaders('GET');
      const request = buildRequest('GET', headers, controller.signal, transformedFilters);
      const apiResponse = await sendRequest<IProduct[] | IApiException>(apiUrl, request, controller);
      return handleApiResponse<IProduct[]>(apiResponse);
    } catch (error: unknown) {
      setIsError(true);
      logError(error, { details: 'failed on getting filtered products.', ...filters, url: apiUrl });
      return null;
    }
  }, []);

  return { getProductsByIds, getVariants, getProductVariants, getDetails, getProductDetails, getFilteredProducts, isLoading, isError }
};

export default useApi;