'use client';

import { RecentlyViewedContext } from '@/contexts/recenty-viewed.context';
import useApi from '@/hooks/useApi';
import { useProductCookies } from '@/hooks/useProductCookies';
import { logError } from '@/lib/api/logger/logger.client';
import type { IProduct } from '@/types/types';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

export function RecentlyViewedProvider({ children, initialProducts, validatedIds }: { children: ReactNode; initialProducts: IProduct[]; validatedIds: number[] }) {
  const { getProductsByIds } = useApi();
  const { storeRecentlyViewedIds, clearRecentlyViewedIds } = useProductCookies('recent');
  const [recentlyViewedIds, setRecentlyViewedIds] = useState<number[]>(validatedIds);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<IProduct[]>(initialProducts);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const recentlyViewedCount = useMemo(() => recentlyViewedIds.length, [recentlyViewedIds]);

  const isRecentlyViewed = useCallback((productId: number): boolean => {
    return recentlyViewedIds.includes(productId);
  }, [recentlyViewedIds]);

  const removeIdFromRecentlyViewed = useCallback((id: number) => {
    setRecentlyViewedIds((prev) => prev.filter((item) => item !== id));
  }, []);

  const loadRecentlyViewedProducts = useCallback(async (productIds: number[]) => {
    if (isLoading || productIds.length === 0) {
      return;
    }

    setIsLoading(true);
    setIsError(false);

    try {
      const recentlyViewedProducts = await getProductsByIds(productIds);
      if (recentlyViewedProducts) {
        setRecentlyViewedProducts(recentlyViewedProducts);
      }
    } catch (error: unknown) {
      setIsError(true);
      logError(error, { productIds });
    } finally {
      setIsLoading(false);
    }
  }, [getProductsByIds]);

  const addIdToRecentlyViewed = useCallback((productId: number) => {
    setRecentlyViewedIds((prev) => {
      if (prev.includes(productId)) {
        return prev;
      }
      return [productId, ...prev];
    });
  }, []);

  const removeProductFromRecentlyViewed = useCallback((productId: number) => {
    removeIdFromRecentlyViewed(productId);
    setRecentlyViewedProducts((prev) => prev.filter((p) => p.id !== productId));
  }, []);

  const addProductToRecentlyViewed = useCallback((product: IProduct) => {
    addIdToRecentlyViewed(product.id);
    setRecentlyViewedProducts((prev) => {
      if (prev.some((p) => p.id === product.id)) {
        return prev;
      }
      return [product, ...prev];
    });
  }, []);

  useEffect(() => {
    if (recentlyViewedIds) {
      loadRecentlyViewedProducts(recentlyViewedIds);
    }
  }, [recentlyViewedIds, loadRecentlyViewedProducts]);

  useEffect(() => {
    if (recentlyViewedIds.length > 0) {
      storeRecentlyViewedIds(recentlyViewedIds);
    } else {
      clearRecentlyViewedIds();
    }
  }, [recentlyViewedIds]);

  return (
    <RecentlyViewedContext.Provider value={{
      recentlyViewedIds,
      recentlyViewedProducts,
      recentlyViewedCount,
      isLoading,
      isError,
      isRecentlyViewed,
      addProductToRecentlyViewed,
      removeProductFromRecentlyViewed,
      loadRecentlyViewedProducts,
    }}>
      {children}
    </RecentlyViewedContext.Provider>
  );
}
