'use client';

import { useSearch } from '@/contexts/search.context';
import { Input } from '@/components/ui/input';
import { Command, CommandItem, CommandList } from '@/components/ui/command';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import { buildHeaders, sendRequest } from '@/lib/api/http/http.client';
import { buildApiUrl, buildRequest, handleApiResponse } from '@/lib/api/http/http.index';
import { endpoints } from '@/config/endpoints';
import { IProduct } from '@/types/types';
import { IApiException } from '@/lib/api/types/api.types';
import { logError } from '@/lib/api/logger/logger.client';
import { CrossIcon, DeleteIcon, SearchIcon, XCircleIcon } from 'lucide-react';
import { Button } from '../ui/button';
import { useTranslations } from 'next-intl';
import { useSearchRedirect } from '@/hooks/useSearchRedirect';
import Link from 'next/link';

const SearchInput = () => {
  const t = useTranslations();
  const { redirectToSearch } = useSearchRedirect();
  const router = useRouter();
  const { currentQuery, setCurrentQuery, addToHistory, results, setResults, isLoading, searchHistory, setIsLoading, isError, removeFromHistory, setIsError } = useSearch();

  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const headers = buildHeaders('GET');

  useEffect(() => {
    if (currentQuery.trim().length < 2) {
      setResults([]);
      setOpen(false);
      return;
    }

    const timer = setTimeout(async () => {
      setIsLoading(true);
      setIsError(null);

      const controller = new AbortController();
      try {
        const apiUrl = buildApiUrl(endpoints.service.search.GET_RESULTS, { q: currentQuery });
        const request = buildRequest('GET', headers, controller.signal);
        const apiResponse = await sendRequest<IProduct[] | IApiException>(apiUrl, request, controller, 1500);
        const results = handleApiResponse<IProduct[]>(apiResponse);
        setResults(results);
        setOpen(true);
      } catch (error: unknown) {
        logError(error, { details: 'search failed', currentQuery });
        setIsError('Search failed');
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [currentQuery]);

  const goToSearchPage = () => {
    if (!currentQuery.trim()) return;
    addToHistory(currentQuery);
    redirectToSearch({ q: currentQuery });
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full max-w-md" ref={containerRef}>
      <div className="flex flex-row space-x-1">
        <Input
          value={currentQuery}
          onChange={(e) => setCurrentQuery(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && goToSearchPage()}
          placeholder="Search products..."
          className="w-full"
          onFocus={() => {
            setOpen(true);
          }}
        />
        <Button
          size="icon"
          variant="outline"
          onClick={() => currentQuery === '' ? setOpen(false) : setCurrentQuery('')}
        >
          <XCircleIcon className={currentQuery === '' ? 'text-muted-foreground' : ''} />
        </Button>
      </div>

      {open && (
        <div
          className={cn(
            "absolute z-50 mt-1 w-full rounded-md border bg-white shadow-lg",
            "dark:bg-popover dark:border-neutral-700"
          )}
        >
          <Command className="max-h-80 overflow-y-auto">
            <CommandList>
              <CommandItem
                className="font-semibold"
                onSelect={goToSearchPage}
              >
                <SearchIcon />{currentQuery === '' ? 'Start typing...' : `Show all results for ${currentQuery}`}
              </CommandItem>
              {currentQuery.trim().length === 0 && searchHistory.length > 0 && (
                <>
                  <div className="px-2 pt-2 text-sm text-muted-foreground">Recent</div>
                  {searchHistory.map((query, index) => (
                    <CommandItem
                      key={`history-${index}`}
                      onSelect={() => {
                        setCurrentQuery(query);
                        redirectToSearch({ q: currentQuery });
                        setOpen(false);
                        addToHistory(query);
                      }}
                      className="flex justify-between items-center"
                    >
                      <span className="truncate">{query}</span>
                      <Button
                        size="icon"
                        variant="ghost"
                        className="ml-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFromHistory(query);
                        }}
                      >
                        <DeleteIcon className="w-4 h-4" />
                      </Button>
                    </CommandItem>
                  ))}
                  <CommandItem disabled className="h-px my-2 bg-border" />
                </>
              )}

              {isLoading ? (
                <CommandItem>{t('loading')}</CommandItem>
              ) : isError ? (
                <CommandItem>{t('error_occured')}</CommandItem>
              ) : results ? (
                results.length > 0 ? (
                  results.map((product: IProduct) => (
                    <CommandItem
                      key={product.id}
                      onSelect={() => {
                        setCurrentQuery(product.name);
                        router.push(`item/${product.slug}/?id=${product.id}`);
                        setOpen(false);
                      }}
                    >
                      {product.name}
                    </CommandItem>
                  ))
                ) : (
                  <CommandItem>No results</CommandItem>
                )
              ) : null}
            </CommandList>
          </Command>
        </div>
      )}
    </div>
  );
};

export default SearchInput;