'use client';

import { ICategory } from "@/types/types";
import CategoryListItem from "../custom/category-list-item";
import { ScrollArea } from "../ui/scroll-area";
import { Skeleton } from "../ui/skeleton";
import { useTaxonomy } from "@/contexts/taxonomy.context";
import { useTranslations } from "next-intl";

type RootCategoryMenuListProps = {
  onHover: (category: ICategory) => void;
}

const RootCategoryMenuList = ({ onHover }: RootCategoryMenuListProps) => {
  const t = useTranslations();
  const { mainCategories, isLoading } = useTaxonomy();

  return (
    <div className="w-1/3">
      <h4 className="font-semibold mb-2">{t('all_categories')}</h4>
      <ScrollArea className="max-h-[300px] pr-2">
        {isLoading ? (
          <Skeleton className="h-10 w-full" />
        ) : (
          <ul className="space-y-1">
            {mainCategories.map((cat) => (
              <li key={cat.category_id}>
                <CategoryListItem
                  category={cat}
                  isRoot={true}
                  onSelect={() => onHover(cat)}
                  isActive={false}
                />
              </li>
            ))}
          </ul>
        )}
      </ScrollArea>
    </div>
  )
}

export default RootCategoryMenuList;
