'use client';

import { ICategory } from '@/types/types';
import { createContext, useContext } from 'react';

export interface TaxonomyContextType {
  totalProducts: number;
  loadMainCategories: () => void;
  mainCategories: ICategory[];
  subCategories: ICategory[] | null;
  selectedRootCategory: ICategory | null;
  selectedSubCategory: ICategory | null;
  isLoading: boolean;
  isError: boolean;
  setSelectedRootCategory: (category: ICategory) => void;
  getCategoryChildren: (categoryId: number) => Promise<ICategory[] | null>;
  setSelectedSubCategory: (category: ICategory) => void;
}

export const TaxonomyContext = createContext<TaxonomyContextType | undefined>(undefined);

export const useTaxonomy = (): TaxonomyContextType => {
  const context = useContext(TaxonomyContext);
  if (!context) {
    throw new Error('useTaxonomy must be used within a TaxonomyProvider');
  }
  return context;
};
