'use client';

import { endpoints } from "@/config/endpoints";
import { buildHeaders, sendRequest } from "@/lib/api/http/http.client";
import { buildApiUrl, buildRequest, handleApiResponse } from "@/lib/api/http/http.index";
import { logError } from "@/lib/api/logger/logger.server";
import { IApiException } from "@/lib/api/types/api.types";
import { ICategory } from "@/types/types";
import { useCallback, useState } from "react";

export const useCategory = () => {
  const [subCategories, setSubCategories] = useState<ICategory[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getSubCategories = useCallback(async (categoryId: number): Promise<ICategory[] | null | undefined> => {
    if (isLoading) return;
    setIsLoading(true);
    const controller = new AbortController();
    try {
      const apiUrl = buildApiUrl(endpoints.content.taxonomy.GET_CHILDREN_CATEGORIES, { category_id: categoryId });
      const headers = buildHeaders('GET');
      const request = buildRequest('GET', headers, controller.signal);
      const apiResponse = await sendRequest<ICategory[] | IApiException>(apiUrl, request, controller, 3000);
      const subCategories = handleApiResponse<ICategory[]>(apiResponse);
      if (isError) setIsError(false);
      return subCategories;
    } catch (error: any) {
      logError(error, { details: "failed on getting sub categories in 'useCategory' hook.", categoryId });
      setIsError(true);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const loadSubCategories = useCallback(async (categoryId: number) => {
    const subCategories = await getSubCategories(categoryId);
    if (subCategories) {
      setSubCategories(subCategories);
    }
  }, []);

  return { getSubCategories, loadSubCategories, subCategories, isLoading, isError }
}