'use client'

import { useState } from 'react'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Button } from '@/components/ui/button'
import Link from 'next/link'
import { Menu } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useTaxonomy } from '@/contexts/taxonomy.context'
import { ICategory } from '@/types/types'
import { logError } from '@/lib/api/logger/logger.client'

export default function MobileCategoriesMenu() {
  const {
    mainCategories,
    isLoading: isLoadingMain,
    isError: isMainError,
    getCategoryChildren,
  } = useTaxonomy()

  const [open, setOpen] = useState(false)
  const [activeCategory, setActiveCategory] = useState<ICategory | null>(null)
  const [childrenCategories, setChildrenCategories] = useState<ICategory[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSelect = async (cat: ICategory) => {
    setActiveCategory(cat)
    setChildrenCategories([])
    setIsLoading(true)

    try {
      const children = await getCategoryChildren(cat.category_id)
      if (children) setChildrenCategories(children)
    } catch (error: unknown) {
      logError(error, { details: 'Failed loading children categories', cat });
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" aria-label="Open Categories Menu">
          <Menu className="h-6 w-6" />
        </Button>
      </SheetTrigger>

      <SheetContent side="left" className="w-full max-w-sm p-0">
        <div className="flex h-full">
          <div className="w-1/2 overflow-y-auto border-r">
            {isLoadingMain ? (
              <div className="p-4 text-sm text-muted-foreground">Loading...</div>
            ) : isMainError ? (
              <div className="p-4 text-sm text-destructive">Error loading categories</div>
            ) : Array.isArray(mainCategories) && mainCategories.length > 0 ? (
              mainCategories.map((cat) => (
                <Button
                  key={cat.category_id}
                  variant="ghost"
                  onClick={() => handleSelect(cat)}
                  className={cn(
                    'flex w-full justify-start px-4 py-3 text-sm',
                    activeCategory?.category_id === cat.category_id && 'bg-muted'
                  )}
                  aria-current={activeCategory?.category_id === cat.category_id}
                >
                  <span className="truncate">{cat.name}</span>
                </Button>
              ))
            ) : (
              <div className="p-4 text-sm text-muted-foreground">No categories</div>
            )}
          </div>

          <div className="w-1/2 overflow-y-auto">
            {isLoading ? (
              <div className="p-4 text-sm text-muted-foreground">Loading...</div>
            ) : childrenCategories.length > 0 ? (
              childrenCategories.map((subcat) => (
                <Link
                  key={subcat.category_id}
                  href={`/category/${subcat.slug}`}
                  className="block px-4 py-3 text-sm hover:bg-muted"
                  onClick={() => setOpen(false)}
                >
                  <span className="truncate">{subcat.name}</span>
                </Link>
              ))
            ) : activeCategory ? (
              <div className="p-4 text-sm text-muted-foreground">No subcategories</div>
            ) : (
              <div className="p-4 text-sm text-muted-foreground">Select a category</div>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
