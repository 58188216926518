'use client';

'use client';

import { useRouter } from 'next/navigation';
import { IProductFilters } from '@/types/types';

export const useSearchRedirect = () => {
  const router = useRouter();

  const redirectToSearch = (filters: IProductFilters) => {
    const query: Record<string, string> = {};

    if (filters.q) {
      query.q = filters.q;
    }

    if (filters.filters) {
      const activeFlags = Object.entries(filters.filters)
        .filter(([_, value]) => value)
        .map(([key]) => key);

      if (activeFlags.length > 0) {
        query.filters = activeFlags.join(',');
      }
    }

    if (filters.colors && filters.colors.length > 0) {
      query.colors = filters.colors.join(',');
    }

    if (filters.price_range?.min != null) {
      query.min = filters.price_range.min.toString();
    }

    if (filters.price_range?.max != null) {
      query.max = filters.price_range.max.toString();
    }

    const searchParams = new URLSearchParams(query).toString();
    router.push(`/search?${searchParams}`);
  };

  return { redirectToSearch };
};