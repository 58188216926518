'use client';

import { getCategoryIcon } from "@/lib/icons-manager";
import { ICategory } from "@/types/types";

type CategoryListItemProps = {
  category: ICategory;
  isRoot: boolean;
  isActive: boolean;
  onSelect: (category: ICategory) => void;
}

const CategoryListItem = ({ category, isRoot, isActive, onSelect }: CategoryListItemProps) => {
  // className="hover:bg-accent p-2 rounded cursor-pointer text-sm"
  return (
    <li key={category.category_id}
      onMouseEnter={() => onSelect(category)}
      className="cursor-pointer hover:bg-accent rounded px-2 py-1"
    >
      <div className="" onClick={() => onSelect(category)}>
        {getCategoryIcon(category.slug, 16)};
        {category.name}
      </div>
    </li>
  )
}

export default CategoryListItem;
