import { defineRouting } from 'next-intl/routing';

export const routing = defineRouting({
    locales: ['en', 'ru'],
    defaultLocale: 'en',
    localeCookie: {
        name: 'w_locale',
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        secure: true,
        sameSite: 'lax'
    },
    localePrefix: {
        mode: 'as-needed'
    },
    pathnames: {
        '/': '/',
    }
});