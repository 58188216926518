'use client';

import { endpoints } from "@/config/endpoints";
import { TaxonomyContext } from "@/contexts/taxonomy.context";
import { buildHeaders, sendRequest } from "@/lib/api/http/http.client";
import { buildApiUrl, buildRequest, handleApiResponse } from "@/lib/api/http/http.index";
import { logError } from "@/lib/api/logger/logger.client";
import { IApiException } from "@/lib/api/types/api.types";
import { ICategory } from "@/types/types";
import { useCallback, useEffect, useState } from "react";

export function TaxonomyProvider({ children, totalProducts }: { children: React.ReactNode, totalProducts: number }) {
  const [mainCategories, setMainCategories] = useState<ICategory[]>([]);
  const [subCategories, setSubCategories] = useState<ICategory[] | null>(null);
  const [selectedRootCategory, setSelectedRootCategory] = useState<ICategory | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ICategory | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const loadMainCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const controller = new AbortController();
      const apiUrl = buildApiUrl(endpoints.content.taxonomy.GET_ROOT_CATEGORIES);
      const headers = buildHeaders('GET');
      const request = buildRequest('GET', headers, controller.signal, null);
      const apiResponse = await sendRequest<ICategory[] | IApiException>(apiUrl, request, controller, 4000);
      const mainCategories = handleApiResponse<ICategory[]>(apiResponse);
      if (isError) setIsError(false);
      setMainCategories(mainCategories);
    } catch (error: unknown) {
      setIsError(true);
      logError(error, { details: 'failed on loading categories' });
      console.error('failed on loading main categories', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getCategoryChildren = useCallback(async (categoryId: number): Promise<ICategory[] | null> => {
    setIsLoading(true);
    try {
      const controller = new AbortController();
      const apiUrl = buildApiUrl(endpoints.content.taxonomy.GET_CHILDREN_CATEGORIES, { category_id: categoryId });
      const headers = buildHeaders('GET', { withStoredLanguage: true });
      const request = buildRequest('GET', headers, controller.signal);
      const apiResponse = await sendRequest<ICategory[] | IApiException>(apiUrl, request, controller, 3000);
      const categories = handleApiResponse<ICategory[]>(apiResponse);
      return categories;
    } catch (error: unknown) {
      logError(error, { details: 'failed on loading category children', categoryId });
      console.error('Failed on loading category children.', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadMainCategories();
  }, [loadMainCategories]);

  return (
    <TaxonomyContext.Provider value={{
      totalProducts,
      loadMainCategories,
      getCategoryChildren,
      selectedRootCategory,
      setSelectedRootCategory,
      selectedSubCategory,
      setSelectedSubCategory,
      mainCategories,
      subCategories,
      isLoading,
      isError
    }}>
      {children}
    </TaxonomyContext.Provider>
  )
};