'use client';

import { WatchlistContext } from "@/contexts/watchlist.context";
import { IProduct } from "@/types/types";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { logError } from "@/lib/api/logger/logger.server";
import useApi from "@/hooks/useApi";
import { useProductCookies } from "@/hooks/useProductCookies";

export function WatchlistProvider({ children, initialProducts, validatedIds }: { children: ReactNode; initialProducts: IProduct[]; validatedIds: number[] }) {
  const { getProductsByIds } = useApi();
  const { clearWatchlistIds, storeWatchlistIds } = useProductCookies('watchlist');
  const [watchlistIds, setWatchlistIds] = useState<number[]>(validatedIds);
  const [watchlistProducts, setWatchlistProducts] = useState<IProduct[]>(initialProducts);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const watchlistCount = useMemo(() => watchlistIds.length, [watchlistIds]);

  const isInWatchlist = useCallback((productId: number): boolean => {
    return watchlistIds.includes(productId);
  }, [watchlistIds]);

  const addIdToWatchlist = useCallback((productId: number) => {
    setWatchlistIds((prev) => {
      if (prev.includes(productId)) {
        return prev;
      }
      return [productId, ...prev];
    });
  }, []);

  const addProductToWatchlist = useCallback((product: IProduct) => {
    addIdToWatchlist(product.id);
    setWatchlistProducts((prev) => {
      if (prev.some((p) => p.id === product.id)) {
        return prev;
      }
      return [product, ...prev];
    });
  }, []);

  const removeIdFromWatchlist = useCallback((id: number) => {
    setWatchlistIds((prev) => prev.filter((item) => item !== id));
  }, []);

  const removeProductFromWatchlist = useCallback((productId: number) => {
    removeIdFromWatchlist(productId);
    setWatchlistProducts((prev) => prev.filter((p) => p.id !== productId));
  }, [removeIdFromWatchlist]);

  const toggleWatchlist = useCallback((product: IProduct) => {
    if (isInWatchlist(product.id)) {
      removeProductFromWatchlist(product.id);
    } else {
      addProductToWatchlist(product);
    }
  }, [isInWatchlist, removeProductFromWatchlist, addProductToWatchlist]);

  const loadWatchlistProducts = useCallback(async (productIds: number[]) => {
    if (productIds.length === 0) {
      setWatchlistProducts([]);
      return;
    }

    setIsLoading(true);

    try {
      const watchlistProducts = await getProductsByIds(productIds);
      if (watchlistProducts) {
        setWatchlistProducts(watchlistProducts);
      }
      if (isError) setIsError(false);
    } catch (error: unknown) {
      setIsError(true);
      logError(error, { details: 'failed on loading watchlist products', productIds });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (watchlistIds) {
      loadWatchlistProducts(watchlistIds);
    }
  }, [watchlistIds, loadWatchlistProducts]);

  useEffect(() => {
    if (watchlistIds.length > 0) {
      storeWatchlistIds(watchlistIds);
    } else {
      clearWatchlistIds();
    }
  }, [watchlistIds]);

  return (
    <WatchlistContext.Provider value={{
      watchlistIds,
      watchlistProducts,
      watchlistCount,
      isLoading,
      isError,
      isInWatchlist,
      addProductToWatchlist,
      removeProductFromWatchlist,
      toggleWatchlist,
      loadWatchlistProducts
    }}>
      {children}
    </WatchlistContext.Provider>
  )
}

// import React, { ReactNode, useCallback, useEffect, useState, useMemo } from 'react';
// import { WatchlistContext } from '@/contexts/watchlist-context';
// import { IProduct } from '@/types/types';
// import { useApi } from '@/hooks/use-api';
// import { storeWatchlistIds, clearWatchlistIds } from '@/libs/utils/cookie-manager';
// import { logError } from '@/libs/api/client/logger-service';

// type WatchlistProviderProps = {
//     children: ReactNode;
//     initialProducts: IProduct[];
//     validatedIds: number[];
// };

// export function WatchlistProvider({ children, initialProducts, validatedIds }: WatchlistProviderProps) {
//     const { getProductsByIds } = useApi();
//     const [watchlistIds, setWatchlistIds] = useState<number[]>(validatedIds);
//     const [watchlistProducts, setWatchlistProducts] = useState<IProduct[]>(initialProducts);
//     const [isLoading, setIsLoading] = useState<boolean>(false);
//     const [isError, setIsError] = useState<boolean>(false);

//    



//     const loadWatchlistProducts = useCallback(async (productIds: number[]) => {
//         if (productIds.length === 0) {
//             setWatchlistProducts([]);
//             return;
//         }
//         setIsLoading(true);
//         setIsError(false);
//         try {
//             const products = await getProductsByIds(productIds);
//             setWatchlistProducts(products);
//         } catch (error) {
//             setIsError(true);
//             logError(error, { productIds });
//         } finally {
//             setIsLoading(false);
//         }
//     }, [getProductsByIds]);

//     const addIdToWatchlist = useCallback((productId: number) => {
//         setWatchlistIds((prev) => {
//             if (prev.includes(productId)) {
//                 return prev;
//             }
//             return [productId, ...prev];
//         });
//     }, []);

//     const removeIdFromWatchlist = useCallback((id: number) => {
//         setWatchlistIds((prev) => prev.filter((item) => item !== id));
//     }, []);

//     const addProductToWatchlist = useCallback((product: IProduct) => {
//         addIdToWatchlist(product.id);
//         setWatchlistProducts((prev) => {
//             if (prev.some((p) => p.id === product.id)) {
//                 return prev;
//             }
//             return [product, ...prev];
//         });
//     }, [addIdToWatchlist]);

//     const removeProductFromWatchlist = useCallback((productId: number) => {
//         removeIdFromWatchlist(productId);
//         setWatchlistProducts((prev) => prev.filter((p) => p.id !== productId));
//     }, [removeIdFromWatchlist]);

//     const toggleWatchlist = useCallback((product: IProduct) => {
//         if (isInWatchlist(product.id)) {
//             removeProductFromWatchlist(product.id);
//         } else {
//             addProductToWatchlist(product);
//         }
//     }, [isInWatchlist, removeProductFromWatchlist, addProductToWatchlist]);



//     return (
//         <WatchlistContext.Provider value={{
//             watchlistIds,
//             watchlistProducts,
//             watchlistCount,
//             isLoading,
//             isError,
//             isInWatchlist,
//             addProductToWatchlist,
//             removeProductFromWatchlist,
//             toggleWatchlist,
//             loadWatchlistProducts
//         }}>
//             {children}
//         </WatchlistContext.Provider>
//     );
// }
