'use client';

import { startTransition, useState } from 'react';
import { useLocale } from 'next-intl';
import { useParams } from 'next/navigation';
import { useRouter, usePathname } from '@/i18n/navigation';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { useMinWidthQuery } from '@/hooks/useMinWidthQuery';
import { languageMenu } from '@/config/setup';
import { useLocaleSwitcher } from '@/hooks/useLocaleSwitcher';

const LanguageSwitcher = () => {
  const isDesktop = useMinWidthQuery(768);
  const currentLocale = useLocale();
  const { switchLocale } = useLocaleSwitcher();
  const selectedLocale = languageMenu.find((o: { value: string; }) => o.value === currentLocale) || languageMenu[0];
  const [selected, setSelected] = useState(selectedLocale ?? languageMenu[0]);
  const pathname = usePathname()
  const router = useRouter();
  const params = useParams();

  const handleChange = (value: string) => {
    const newLocale = languageMenu.find((o: { value: string; }) => o.value === value);
    if (newLocale) {
      switchLocale(newLocale.value);
    }
  };

  if (!selected) return null;

  return (
    <Select value={selected.value} onValueChange={handleChange}>
      <SelectTrigger className="w-40 sm:w-24 xs:w-20 bg-background">
        <SelectValue>
          <div className="flex items-center justify-center">
            <span className="inline-block shadow-md">{selected.icon}</span>
            {isDesktop && <span className="ml-2">{selected.value.toUpperCase()}</span>}
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {languageMenu.map(menuItem => (
          <SelectItem key={menuItem.value} value={menuItem.value}>
            <div className="flex items-center">
              <span className="inline-block shadow-md">{menuItem.icon}</span>
              {isDesktop && <span className="ml-2">{menuItem.name}</span>}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default LanguageSwitcher;
