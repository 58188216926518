'use client';

import { usePathname, useRouter } from "@/i18n/navigation";
import { logInfo } from "@/lib/api/logger/logger.client";
import { useLocale } from "next-intl";
import { useSearchParams } from "next/navigation";

export function useLocaleSwitcher() {
  const router = useRouter();
  const currentLocale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  function switchLocale(locale: string) {
    console.log('useLocaleSwitcher', { currentLocale, locale, pathname, searchParams });
    logInfo('useLocaleSwitcher', { currentLocale, locale, pathname, searchParams});
  }

  return { switchLocale };
}