'use client';

import { Button } from '@/components/ui/button';
import { useCart } from '@/hooks/useCart';
import { RootState } from '@/store/store';
import { ShoppingCartIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

export default function CartButton() {
  const { openCart } = useCart();
  const totalItems = useSelector((state: RootState) => state.cart.totalItems);

  return (
    <Button
      className="flex-shrink-0"
      variant="outline"
      size="icon"
      onClick={() => openCart()}
      aria-label="cart-button"
    >
      <ShoppingCartIcon />
      {/* <span className="cart-counter-badge bg-heading absolute -top-2.5 flex items-center justify-center rounded-full font-bold text-white xl:-top-3 -right-2.5 xl:-right-3">
        {totalItems}
      </span> */}
    </Button>
  );
}
