'use client';

import { Button } from '@/components/ui/button';
import { LogInIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';

export const AuthButton = () => {
  const router = useRouter();

  return (
    <Button
      variant="outline"
      size="icon"
      className="relative shrink-0"
      onClick={() => router.push('/login')}
    >
      <LogInIcon className="size-[1.2rem]" />
    </Button>
  );
};
