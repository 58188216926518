import BeautyProductsIcon from "@/components/icons/beauty-products-icon"
import {
  DogIcon,
  DollarSignIcon,
  ClapperboardIcon,
  BabyIcon,
  PackageIcon,
  BriefcaseIcon,
  CameraIcon,
  ChurchIcon,
  TvIcon,
  PizzaIcon,
  SofaIcon,
  HammerIcon,
  HeartPulseIcon,
  FlowerIcon,
  HandshakeIcon,
  EyeIcon,
  FileVideoIcon,
  PencilRulerIcon,
  TerminalIcon,
  DumbbellIcon,
  Gamepad2Icon,
  CarIcon,
  CircleHelpIcon,
} from "lucide-react";
import { ReactNode } from "react"

export const getCategoryIcon = (slug: string, size: number = 64, color: string = "#FF4500"): ReactNode => {
  switch (slug) {
    case 'animals-and-pet-supplies':
      return <DogIcon size={size} color={color} />
    case 'apparel-and-accessories':
      return <DollarSignIcon size={size} color={color} />
    case 'arts-and-entertainment':
      return <ClapperboardIcon size={size} color={color} />
    case 'baby-and-toddler':
      return <BabyIcon size={size} color={color} />
    case 'bundles':
      return <PackageIcon size={size} color={color} />
    case 'business-and-industrial':
      return <BriefcaseIcon size={size} color={color} />
    case 'cameras-and-optics':
      return <CameraIcon size={size} color={color} />
    case 'religious-and-ceremonial':
      return <ChurchIcon size={size} color={color} />
    case 'electronics':
      return <TvIcon size={size} color={color} />
    case 'food-beverages-and-tobacco':
      return <PizzaIcon size={size} color={color} />
    case 'furniture':
      return <SofaIcon size={size} color={color} />
    case 'hardware':
      return <HammerIcon size={size} color={color} />
    case 'health-and-beauty':
      return <HeartPulseIcon size={size} color={color} />
    case 'home-and-garden':
      return <FlowerIcon size={size} color={color} />
    case 'services':
      return <HandshakeIcon size={size} color={color} />
    case 'luggage-and-bags':
      return <BriefcaseIcon size={size} color={color} />
    case 'mature':
      return <EyeIcon size={size} color={color} />
    case 'media':
      return <FileVideoIcon size={size} color={color} />
    case 'office-supplies':
      return <PencilRulerIcon size={size} color={color} />
    case 'software':
      return <TerminalIcon size={size} color={color} />
    case 'sporting-goods':
      return <DumbbellIcon size={size} color={color} />
    case 'toys-and-games':
      return <Gamepad2Icon size={size} color={color} />
    case 'vehicles-and-parts':
      return <CarIcon size={size} color={color} />
    default:
      return <CircleHelpIcon size={size} color={color} />
  }
}