'use client';

import { AlertDialog, AlertDialogContent } from '@/components/ui/alert-dialog';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Drawer, DrawerContent } from '@/components/ui/drawer';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { UiContext } from '@/contexts/ui.context';
import { useMinWidthQuery } from '@/hooks/useMinWidthQuery';
import { Level } from '@/lib/api/types/api.enums';
import { ThemeProvider } from 'next-themes';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { toast, Toaster } from 'sonner';

const UiProvider = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMinWidthQuery(768);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
  const [drawerContent, setDrawerContent] = useState<ReactNode | null>(null);
  const [sheetContent, setSheetContent] = useState<ReactNode | null>(null);

  const showModal = useCallback((content: ReactNode) => {
    setModalContent(content);
    setIsModalOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
    setModalContent(null);
  }, []);

  const showDialog = useCallback((content: ReactNode) => {
    setDialogContent(content);
    setIsDialogOpen(true);
  }, []);

  const hideDialog = useCallback(() => {
    setIsDialogOpen(false);
    setDialogContent(null);
  }, []);

  const showDrawer = useCallback((content: ReactNode) => {
    setDrawerContent(content);
    setIsDrawerOpen(true);
  }, []);

  const hideDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setDrawerContent(null);
  }, []);

  const showSheet = useCallback((content: ReactNode) => {
    setSheetContent(content);
    setIsSheetOpen(true);
  }, []);

  const hideSheet = useCallback(() => {
    setIsSheetOpen(false);
    setSheetContent(null);
  }, []);

  const showResponsive = useCallback((content: ReactNode) => {
    if (isDesktop) {
      showDialog(<DialogContent>{content}</DialogContent>);
    } else {
      showDrawer(<DrawerContent>{content}</DrawerContent>);
    }
  }, [isDesktop, showDialog, showDrawer]);

  const hideResponsive = useCallback(() => {
    if (isDrawerOpen) {
      hideDrawer();
    } else if (isDialogOpen) {
      hideDialog();
    }
  }, [isDrawerOpen, isDialogOpen]);

  const showToast = useCallback((id: string, title: string, level: Level) => {
    if (level === Level.ERROR || level === Level.CRITICAL) {
      toast.error(title, { id });
    } else if (level === Level.WARNING || level === Level.ALERT) {
      toast.warning(title, { id });
    } else if (level === Level.INFO) {
      toast.info(title, { id });
    } else if (level === Level.SUCCESS) {
      toast.success(title, { id });
    } else {
      toast.message(title, { id });
    }
  }, []);

  const hideToast = useCallback((toastId?: string) => {
    toast.dismiss(toastId);
  }, []);

  const contextValue = useMemo(
    () => ({
      isDesktop,
      isModalOpen,
      showModal,
      hideModal,
      isDialogOpen,
      showDialog,
      hideDialog,
      isDrawerOpen,
      showDrawer,
      hideDrawer,
      isSheetOpen,
      showSheet,
      hideSheet,
      isToastVisible,
      showToast,
      hideToast,
      showResponsive,
      hideResponsive
    }),
    [
      isDesktop,
      isModalOpen,
      showModal,
      hideModal,
      isDialogOpen,
      showDialog,
      hideDialog,
      isDrawerOpen,
      showDrawer,
      hideDrawer,
      isSheetOpen,
      showSheet,
      hideSheet,
      isToastVisible,
      showToast,
      hideToast,
      showResponsive,
      hideResponsive
    ],
  );

  return (
    <UiContext.Provider value={contextValue}>
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem disableTransitionOnChange>
        <Toaster richColors position={isDesktop ? 'top-right' : 'bottom-center'} />
        <AlertDialog open={isModalOpen} onOpenChange={() => hideModal()}>
          <AlertDialogContent>{modalContent}</AlertDialogContent>
        </AlertDialog>
        <Dialog open={isDialogOpen} onOpenChange={() => hideDialog()}>
          <DialogContent>{dialogContent}</DialogContent>
        </Dialog>
        {children}
        <Drawer open={isDrawerOpen} onOpenChange={() => hideDrawer()}>
          <DrawerContent>{drawerContent}</DrawerContent>
        </Drawer>
        <Sheet open={isSheetOpen}onOpenChange={() => hideSheet()}>
          <SheetContent>{sheetContent}</SheetContent>
        </Sheet>
      </ThemeProvider>
    </UiContext.Provider>
  );
};

export default UiProvider;
