'use client';

import { SearchContext, SearchContextType } from '@/contexts/search.context';
import { useState, useCallback, useMemo, ReactNode, useEffect } from 'react';

const STORAGE_KEY = 'search-history';

export const SearchProvider = ({ children }: { children: ReactNode }) => {
	const [currentQuery, setCurrentQuery] = useState('');
	const [results, setResults] = useState<any[]>([]);
	const [searchHistory, setSearchHistory] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState<string | null>(null);

	useEffect(() => {
		const saved = localStorage.getItem(STORAGE_KEY);
		if (saved) {
			try {
				const parsed = JSON.parse(saved);
				if (Array.isArray(parsed)) setSearchHistory(parsed);
			} catch {
				localStorage.removeItem(STORAGE_KEY);
			}
		}
	}, []);

	useEffect(() => {
		localStorage.setItem(STORAGE_KEY, JSON.stringify(searchHistory));
	}, [searchHistory]);

	const addToHistory = useCallback((query: string) => {
		const trimmed = query.trim();
		if (!trimmed) return;
		setSearchHistory((prev) => {
			if (prev.includes(trimmed)) return prev;
			return [trimmed, ...prev.slice(0, 19)];
		});
	}, []);

  const removeFromHistory = useCallback((query: string) => {
    console.log('REMOVING FROM HISTORY:', query);
    setSearchHistory((prev) => prev.filter((item) => item !== query));
  }, []);

	const clearHistory = useCallback(() => {
		setSearchHistory([]);
		localStorage.removeItem(STORAGE_KEY);
	}, []);

  const value: SearchContextType = useMemo(() => ({
    currentQuery,
    setCurrentQuery,
    results,
    setResults,
    searchHistory,
    addToHistory,
    removeFromHistory,
    clearHistory,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
  }), [
    currentQuery,
    results,
    searchHistory,
    isLoading,
    isError,
    addToHistory,
    removeFromHistory,
    clearHistory,
  ]);

	return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
