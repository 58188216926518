'use client';

import { IProduct } from '@/types/types';
import { createContext, useContext } from 'react';

export interface RecentlyViewedContextType {
  recentlyViewedIds: number[] | null;
  recentlyViewedCount: number;
  recentlyViewedProducts: IProduct[] | [];
  isLoading: boolean;
  isError: boolean;
  isRecentlyViewed: (productId: number) => boolean;
  addProductToRecentlyViewed: (product: IProduct) => void;
  removeProductFromRecentlyViewed: (productId: number) => void;
  loadRecentlyViewedProducts: (productIds: number[]) => void;
}

export const RecentlyViewedContext = createContext<RecentlyViewedContextType | undefined>(undefined);

export const useRecentlyViewed = (): RecentlyViewedContextType => {
  const context = useContext(RecentlyViewedContext);
  if (!context) {
    throw new Error('useRecentlyViewed must be used within a RecentlyViewedProvider');
  }
  return context;
};
